import React, { useState, useEffect } from "react";
import "./homepageCompSix.css";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariants = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
  hidden: { opacity: 0, scale: 0 },
};

const squareVariantsOnUpa = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: -1, y: 100 },
};

const squareVariantsOnUpbb = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const squareVariantsO = {
  visible: { opacity: 1, scale: 1, transition: { duration: 2 } },
  hidden: { opacity: -1, scale: -1 },
};

const squareVariantsT = {
  visible: { opacity: 1, scale: 1, transition: { duration: 3 } },
  hidden: { opacity: -2, scale: -2 },
};

const squareVariantsF = {
  visible: { opacity: 1, scale: 1, transition: { duration: 4 } },
  hidden: { opacity: -3, scale: -3 },
};

const HomepageCompSix = () => {
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className=" HomepageCompSix-packFamFlex">
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOnUpbb}
        className=" HomepageCompSix-packFam"
      >
        <div className="HomepageCompSix-heading">
          Simple to design, edit and share
        </div>
        <div
          className="HomepageCompSix-subHeading"
          style={{ background: "#476665" }}
        >
          Digital business cards can be accessed easily by anyone with a
          smartphone or computer, editable profiles are created for owners and
          provided with ease to share
        </div>
      </motion.div>

      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOnUpbb}
        className=" HomepageCompSix-packFam"
        style={{ background: "#163D70" }}
      >
        <div className="HomepageCompSix-heading">More room for creativity</div>
        <div className="HomepageCompSix-subHeading">
          Digital cards allows you edit the information you want to be shared
          with others.
        </div>
      </motion.div>

      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOnUpbb}
        className=" HomepageCompSix-packFam"
        style={{ background: "#171923" }}
      >
        <div className="HomepageCompSix-heading">Cost effective</div>
        <div className="HomepageCompSix-subHeading">
          Information shared about yourself can be changed from time to time
          using thesame digital card.
        </div>
      </motion.div>

      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOnUpbb}
        className=" HomepageCompSix-packFam"
        style={{ background: " #36A6A4" }}
      >
        <div className="HomepageCompSix-heading">Interactive</div>
        <div className="HomepageCompSix-subHeading">
          Digital business cards helps you share more personal information about
          yourself and/or your business.i.e. Video links, images,
          websites,about, social media, numerous contact, etc
        </div>
      </motion.div>
    </div>
  );
};

export default HomepageCompSix;
