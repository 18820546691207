import React from "react";
// import { Routes } from "react-router-dom";
import Routes from "./routes";
import { AuthorizerProvider } from "@authorizerdev/authorizer-react";

const Root = () => {
  return (
    <React.Fragment>
      {/* <AuthorizerProvider
        config={{
          authorizerURL: "https://authorizer-production-3e31.up.railway.app",
          redirectURL: window.location.origin,
          clientID: "7d8963c8-4eab-4d53-91b0-5d57a6721dd2",
        }}
      > */}
      <Routes />
      {/* </AuthorizerProvider> */}
    </React.Fragment>
  );
};

export default Root;
