import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import contactOverlay from "../../assets/images/contactOverlay.svg";
import contactOverr from "../../assets/images/contactOverr.jpg";
import pricingComOne from "../../assets/images/pricingComOne.svg";
import contactUsImgBac from "../../assets/images/contactUsImgBac.svg";
import contactUsImgMob from "../../assets/images/contactUsImgMob.svg";
import "./contactPage.css";
import GenFaqPage from "../../components/genFaqPage/GenFaqPage";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const ContactPage = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  return (
    <div className="bodyPos">
      <Navbar />
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
      >
        <div className="faqConatSec">
          {screenWidth > 992 && (
            <div
              style={{
                backgroundImage: `url(${contactOverr})`,
                // backgroundRepeat: "no-repeat",
                backgroundSize: "auto",
                // backgroundColor: "#fefefe",
                height: "663px",
                paddingTop: "70px",
                marginRight: "100px",
                width: "100%",
              }}
              className="contactPage-famGen"
            >
              <div className="contactPage-famFlex1">
                <div className="contactPage-famFlex1tess">
                  Email us a line or chat on{" "}
                  <span className="contactPage-whatsApp">Whatsapp</span>
                </div>
              </div>
              <div className="contactPage-famFlex2">
                <div className="contactPage-famFlexText">
                  <div className="contactPage-famFlexText-emailInput">
                    <input type="email" placeholder="Enter Email" />
                  </div>
                  <div className="contactPage-famFlexText-messageInput">
                    <textarea
                      rows={4}
                      cols={22}
                      maxLength={250}
                      placeholder="Enter text"
                    />
                  </div>
                  <div className="contactPage-famFlexText-sendInput">Send</div>
                </div>
              </div>
            </div>
          )}

          {screenWidth < 992 && (
            <div
              style={{
                backgroundImage: `url(${contactUsImgMob})`,
                backgroundColor: "#fefefe",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100%",
              }}
              className="contactPageF-famGen"
            >
              <div className="contactPageF-famImg">
                <img src={pricingComOne} />
              </div>
              <div className="contactPageF-Flex2">
                <div className="contactPageF-whatsTextFam">
                  <div className="contactPageF-emailText">
                    Email us a line or chat on{" "}
                    <span className="contactPageF-whatstext">Whatsapp</span>
                  </div>
                </div>
                <div className="contactPageF-inputFam">
                  <div className="contactPageF-emailInput">
                    <input type="email" placeholder="Enter Email" />
                  </div>
                  <div className="contactPageF-messageInput">
                    <textarea
                      rows={4}
                      cols={22}
                      maxLength={250}
                      placeholder="Enter text"
                    />
                  </div>
                  <div className="contactPageF-sendInput">Send</div>
                </div>
              </div>
            </div>
          )}
          <GenFaqPage />
        </div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default ContactPage;
