import React, { useEffect, useState } from "react";
import pricingTick from "../../assets/images/pricingTick.svg";

import secondCardPrice from "../../assets/images/secondCardPrice.svg";
import secondSec1 from "../../assets/images/secondSec1.svg";
import secondSec2 from "../../assets/images/secondSec2.svg";
import secondSec3 from "../../assets/images/secondSec3.svg";
import secondSec4 from "../../assets/images/secondSec4.svg";
import secondSec5 from "../../assets/images/secondSec5.svg";

import "./pricingCompTwoC.css";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 1 } },
  hidden: { opacity: 0 },
};

const squareVariantsOnUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};
const PricingCompTwoC = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const [isActive, setIsActive] = useState(1);

  useEffect(() => {
    let timerID = setTimeout(() => {
      if (isActive === 5) {
        setIsActive(1);
      } else {
        setIsActive((prevValue) => prevValue + 1);
      }
    }, 2000);

    return () => clearTimeout(timerID);
  }, [isActive]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsOnUp}
      className="pricingTickCFam-Gen"
    >
      {/* <div className="pricingTickC-text">Free Plan</div>
      <div className="pricingTickC-text-back">Individual</div>
      <div className="pricingTickC-text-w">What You’ll Get</div>
      <div>
        <div className="pricingTickCFam">
          <div className="pricingTickC-textImg">
            <img src={pricingTick} />
          </div>
          <div className="pricingTickC-textOpt">
            Create a single free profile
          </div>
        </div>
        <div className="pricingTickCFam">
          <div className="pricingTickC-textImg">
            <img src={pricingTick} />
          </div>
          <div className="pricingTickC-textOpt">
            Create a single free profile
          </div>
        </div>
        <div className="pricingTickCFam">
          <div className="pricingTickC-textImg">
            <img src={pricingTick} />
          </div>
          <div className="pricingTickC-textOpt">
            Create a single free profile
          </div>
        </div>
        <div className="pricingTickCFam">
          <div className="pricingTickC-textImg">
            <img src={pricingTick} />
          </div>
          <div className="pricingTickC-textOpt">
            Create a single free profile
          </div>
        </div>
      </div> */}

      {/* <div className="image-relContTwo">
      
        {isActive === 1 && (
          <div className="secondSingleCompImg">
            <img src={secondSec1} />
          </div>
        )}
        {isActive === 2 && (
          <div className="secondSingleCompImg">
            <img src={secondSec2} />
          </div>
        )}
        {isActive === 3 && (
          <div className="secondSingleCompImg">
            <img src={secondSec3} />
          </div>
        )}
        {isActive === 4 && (
          <div className="secondSingleCompImg">
            <img src={secondSec4} />
          </div>
        )}
        {isActive === 5 && (
          <div className="secondSingleCompImg">
            <img src={secondSec5} />
          </div>
        )}
      </div> */}

      <div className="image-relCont">
        {/* <div className="pricing-FirstCard">
          <img src={firstCardPrice} />
        </div> */}
        {/* {isActive === 1 && ( */}
        <div className="firstSingleCompImg">
          <img
            src={secondSec1}
            style={{
              zIndex:
                isActive === 1
                  ? "13"
                  : isActive === 2
                  ? "2"
                  : isActive === 3
                  ? "4"
                  : isActive === 4
                  ? "6"
                  : isActive === 5
                  ? "8"
                  : "10",
              opacity:
                isActive === 1
                  ? "1"
                  : isActive === 2
                  ? "0.45"
                  : isActive === 3
                  ? "0.55"
                  : isActive === 4
                  ? "0.65"
                  : isActive === 5
                  ? "0.75"
                  : "0.85",
              bottom:
                isActive === 1
                  ? "0px"
                  : isActive === 2
                  ? "-50px"
                  : isActive === 3
                  ? "-40px"
                  : isActive === 4
                  ? "-30px"
                  : isActive === 5
                  ? "-20px"
                  : "-10px",
              transition: "0.5s",
            }}
          />
        </div>
        {/* )} */}

        {/* {isActive === 2 && ( */}
        <div className="firstSingleCompImg">
          <img
            src={secondSec2}
            style={{
              zIndex:
                isActive === 2
                  ? "13"
                  : isActive === 3
                  ? "2"
                  : isActive === 4
                  ? "4"
                  : isActive === 5
                  ? "6"
                  : isActive === 1
                  ? "8"
                  : "10",
              opacity:
                isActive === 2
                  ? "1"
                  : isActive === 3
                  ? "0.45"
                  : isActive === 4
                  ? "0.55"
                  : isActive === 5
                  ? "0.65"
                  : isActive === 1
                  ? "0.75"
                  : "0.85",
              bottom:
                isActive === 2
                  ? "0px"
                  : isActive === 3
                  ? "-50px"
                  : isActive === 4
                  ? "-40px"
                  : isActive === 5
                  ? "-30px"
                  : isActive === 1
                  ? "-20px"
                  : "-10px",
              transition: "0.5s",
            }}
          />
        </div>
        {/* )} */}
        {/* {isActive === 3 && ( */}
        <div className="firstSingleCompImg">
          <img
            src={secondSec3}
            style={{
              zIndex:
                isActive === 3
                  ? "13"
                  : isActive === 4
                  ? "2"
                  : isActive === 5
                  ? "4"
                  : isActive === 1
                  ? "8"
                  : isActive === 2
                  ? "8"
                  : "10",
              opacity:
                isActive === 3
                  ? "1"
                  : isActive === 4
                  ? "0.45"
                  : isActive === 5
                  ? "0.55"
                  : isActive === 1
                  ? "0.65"
                  : isActive === 2
                  ? "0.75"
                  : "0.85",
              bottom:
                isActive === 3
                  ? "0px"
                  : isActive === 4
                  ? "-50px"
                  : isActive === 5
                  ? "-40px"
                  : isActive === 1
                  ? "-30px"
                  : isActive === 2
                  ? "-20px"
                  : "-10px",
              transition: "0.5s",
            }}
          />
        </div>
        {/* )} */}

        {/* {isActive === 4 && ( */}
        <div className="firstSingleCompImg">
          <img
            src={secondSec4}
            style={{
              zIndex:
                isActive === 4
                  ? "13"
                  : isActive === 5
                  ? "2"
                  : isActive === 1
                  ? "4"
                  : isActive === 2
                  ? "6"
                  : isActive === 3
                  ? "8"
                  : "10",
              opacity:
                isActive === 4
                  ? "1"
                  : isActive === 5
                  ? "0.45"
                  : isActive === 1
                  ? "0.55"
                  : isActive === 2
                  ? "0.65"
                  : isActive === 3
                  ? "0.75"
                  : "0.85",
              bottom:
                isActive === 4
                  ? "0px"
                  : isActive === 5
                  ? "-50px"
                  : isActive === 1
                  ? "-40px"
                  : isActive === 2
                  ? "-30px"
                  : isActive === 3
                  ? "-20px"
                  : "-10px",
              transition: "0.5s",
            }}
          />
        </div>
        {/* )} */}

        {/* {isActive === 5 && ( */}
        <div className="firstSingleCompImg">
          <img
            src={secondSec5}
            style={{
              zIndex:
                isActive === 5
                  ? "13"
                  : isActive === 1
                  ? "2"
                  : isActive === 2
                  ? "4"
                  : isActive === 3
                  ? "6"
                  : isActive === 4
                  ? "8"
                  : "10",
              opacity:
                isActive === 5
                  ? "1"
                  : isActive === 1
                  ? "0.45"
                  : isActive === 2
                  ? "0.55"
                  : isActive === 3
                  ? "0.65"
                  : isActive === 4
                  ? "0.75"
                  : "0.85",
              bottom:
                isActive === 5
                  ? "0px"
                  : isActive === 1
                  ? "-50px"
                  : isActive === 2
                  ? "-40px"
                  : isActive === 3
                  ? "-30px"
                  : isActive === 4
                  ? "-20px"
                  : "-10px",
              transition: "0.5s",
            }}
          />
        </div>
        {/* )} */}
      </div>

      <div className="pricingTickCTextt">DEETS card with text and logo </div>
      <div className="break-line"></div>
      <div className="pricingTickCPrice">
        <div
          className="pricingTickCPrice-amount"
          style={{ fontFamily: "roboto" }}
        >
          &#8358;20,000
        </div>
        <div className="pricingTickC-year">/Year</div>
      </div>
      <div className="pricingTickCPrice-started">Get your card now</div>
    </motion.div>
  );
};

export default PricingCompTwoC;
