import React, { useEffect } from "react";
import "./homepageCompThree.css";
import pixelComThree from "../../assets/images/pixelComThree.png";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useState } from "react";

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const squareVariantsTw = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const squareVariantsOn = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  hidden: { opacity: -1, x: 100 },
};

const squareVariantsOnUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: -1, y: 100 },
};
const squareVariantsOnUpO = {
  visible: { opacity: 1, y: 0, transition: { duration: 3 } },
  hidden: { opacity: -3, y: 100 },
};
const squareVariantsOnUpT = {
  visible: { opacity: 1, y: 0, transition: { duration: 4 } },
  hidden: { opacity: -4, y: 100 },
};

const squareVariantsOnTopUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 100 },
};

const HomepageCompThree = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsOnUp}
    >
      <div className="homepageCompThree-flexdd">
        {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariants}
        className="homepageCompThree-flexFirst"
      > */}

        <div className="homepageCompThree-flexFirst">
          <img src={pixelComThree} />
          {/* </motion.div> */}
        </div>
        {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOn}
        className="homepageCompThree-flexTwo"
      > */}

        <div className="homepageCompThree-flexTwo">
          <div className="homepageCompThree-flexTwotext">
            Turn interactions into networks and build profitable relationships
          </div>
          <div className="homepageCompThree-flexTwotextSub">
            {/* <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariantsOnUp}
            className="homepageCompThree-TwotextSubone"
          > */}
            <div className="homepageCompThree-TwotextSubone">
              For Professionals
              {/* </motion.div> */}
            </div>
            {/* <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariantsOnUpO}
            className="homepageCompThree-TwotextSubtwo"
          > */}
            <div className="homepageCompThree-TwotextSubtwo">
              For Business people
              {/* </motion.div> */}
            </div>
            {/* <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariantsOnUpT}
            className="homepageCompThree-TwotextSubthree"
          > */}
            <div className="homepageCompThree-TwotextSubthree">
              For you
              {/* </motion.div> */}
            </div>
          </div>
          {/* </motion.div> */}
        </div>
      </div>
    </motion.div>
  );
};

export default HomepageCompThree;
