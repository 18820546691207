import React, { useState, useEffect } from "react";
import "./homepageCompTwo.css";
import tapFrame from "../../assets/icons/tapFrame.svg";
import pixelComTwo from "../../assets/images/pixelComTwo.svg";
import ScrollAnimation from "react-animate-on-scroll";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
const squareVariants = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const squareVariantsBtn = {
  visible: { opacity: 1, scale: 1, transition: { duration: 2 } },
  hidden: { opacity: -1, scale: 0 },
};

const squareVariantsTw = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const squareVariantsOn = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  hidden: { opacity: -1, x: -100 },
};
const squareVariantsCompUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const HomepageCompTwo = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsCompUp}
      className="homepageCompTwo-flexd"
    >
      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOn}
        className="homepageCompTwo-flexLeft"
      > */}
      <div className="homepageCompTwo-flexLeft">
        <div className="homepageCompTwo-LeftOnete">Network with a </div>

        <div className="homepageCompTwo-LeftOneImg">
          <img src={tapFrame} />
        </div>

        <div className="homepageCompTwo-LeftOneFuText">
          All the business cards you need in 1. You do not need to carry the
          more than one business card ever again.
        </div>
        {/* <ScrollAnimation
          animateIn="wobble"
          initiallyVisible={true}
          animateOnce={true}
        > */}

        {/* <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariantsBtn}
          className="homepageCompTwo-LeftOneBtn"
        > */}
        <div className="homepageCompTwo-LeftOneBtn">
          {/* <div className="homepageCompTwo-LeftOneBtn"> */}
          Contact Us
        </div>
        {/* </div> */}
        {/* </motion.div> */}
        {/* </ScrollAnimation> */}
        {/* </motion.div> */}
      </div>
      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariants}
        className="homepageCompTwo-RightImg"
      > */}

      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsCompUp}
        className="homepageCompTwo-RightImg"
      >
        <img src={pixelComTwo} />
      </motion.div>
      {/* </motion.div> */}
    </motion.div>
  );
};

export default HomepageCompTwo;
