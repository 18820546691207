import React, { useEffect, useState } from "react";
import pricingTick from "../../assets/images/pricingTick.svg";
import thirdCardPrice from "../../assets/images/thirdCardPrice.svg";

import "./pricingCompTwoD.css";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 1 } },
  hidden: { opacity: 0 },
};

const squareVariantsOnUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};
const PricingCompTwoD = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const [isActive, setIsActive] = useState(1);

  // useEffect(() => {
  //   let timerID = setTimeout(() => {
  //     if (isActive === 5) {
  //       setIsActive(1);
  //     } else {
  //       setIsActive((prevValue) => prevValue + 1);
  //     }
  //   }, 3000);

  //   return () => clearTimeout(timerID);
  // }, [isActive]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsOnUp}
      className="pricingTickDFam-Gen"
    >
      {/* <div className="pricingTickD-text">Free Plan</div>
      <div className="pricingTickD-text-back">Individual</div>
      <div className="pricingTickD-text-w">What You’ll Get</div>
      <div>
        <div className="pricingTickDFam">
          <div className="pricingTickD-textImg">
            <img src={pricingTick} />
          </div>
          <div className="pricingTickD-textOpt">
            Create a single free profile
          </div>
        </div>
        <div className="pricingTickDFam">
          <div className="pricingTickD-textImg">
            <img src={pricingTick} />
          </div>
          <div className="pricingTickD-textOpt">
            Create a single free profile
          </div>
        </div>
        <div className="pricingTickDFam">
          <div className="pricingTickD-textImg">
            <img src={pricingTick} />
          </div>
          <div className="pricingTickD-textOpt">
            Create a single free profile
          </div>
        </div>
        <div className="pricingTickDFam">
          <div className="pricingTickD-textImg">
            <img src={pricingTick} />
          </div>
          <div className="pricingTickD-textOpt">
            Create a single free profile
          </div>
        </div>
      </div> */}

      <div className="image-relContThree">
        {/* <div className="pricing-FirstCard">
          <img src={firstCardPrice} />
        </div> */}
        {isActive === 1 && (
          <div className="thirdSingleCompImg">
            <img src={thirdCardPrice} />
          </div>
        )}{" "}
      </div>

      <div className="pricingTickDTextt">
        <div className="pricingTickDword">DEETS card with logo only</div>
        <div className="pricingTickDSubWord">Suitable for teams accounts</div>
      </div>
      <div className="break-line"></div>
      <div className="pricingTickDPrice">
        <div
          className="pricingTickDPrice-amount"
          style={{ fontFamily: "roboto" }}
        >
          &#8358;20,000
        </div>
        <div className="pricingTickD-year">/Year</div>
      </div>
      <div className="pricingTickDPrice-started">Get your card now</div>
    </motion.div>
  );
};

export default PricingCompTwoD;
