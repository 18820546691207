import React, { useState, useEffect } from "react";
import "./teampageCompOne.css";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariants = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 0 },
};

const squareVariantsOn = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
  hidden: { opacity: -1, scale: -1 },
};
const squareVariantsTw = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1.5 } },
  hidden: { opacity: -2, scale: -2 },
};
const squareVariantsTh = {
  visible: { opacity: 1, scale: 1, transition: { duration: 2 } },
  hidden: { opacity: -3, scale: -3 },
};

const squareVariantsOnUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const TeampageCompOne = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsOnUp}
      className="teampage-compOneFam"
      // style={{ background: "#476665" }}
    >
      {/* <div className="teampage-compOneFam"> */}
      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariants}
        className="teampage-compOnep"
        style={{ background: "#476665" }}
      > */}

      <div style={{ background: "#476665" }} className="teampage-compOnep">
        <div className="teampage-compOnepp">Create templates for all teams</div>
        <div className="teampage-compOneppp">
          Have templates set up for more than 1 team and easily add team members
          to them without editing repeatedly
        </div>
      </div>
      {/* </motion.div> */}

      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOn}
        className="teampage-compOnep"
        style={{ background: "#8CB1E2" }}
      > */}
      <div style={{ background: "#8CB1E2" }} className="teampage-compOnep">
        <div className="teampage-compOnepp">Manage all Team contacts</div>
        <div className="teampage-compOneppp">
          All connections made by team members can be managed easily by the
          admin
        </div>
      </div>
      {/* </motion.div> */}

      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsTw}
        className="teampage-compOnep"
        style={{ background: "#8394E4" }}
      > */}

      <div style={{ background: "#8394E4" }} className="teampage-compOnep">
        <div className="teampage-compOnepp">Share securely</div>
        <div className="teampage-compOneppp">
          Information shared by a team member is secure and is updated on the
          connections dashboard.
        </div>
      </div>
      {/* </motion.div> */}
      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsTh}
        className="teampage-compOnep"
        style={{ background: "#758382" }}
      > */}
      <div style={{ background: "#758382" }} className="teampage-compOnep">
        <div className="teampage-compOnepp">Match your brand theme</div>
        <div className="teampage-compOneppp">
          Have different colors for your brand and you want your team to share
          using that color? You can select and use your personal brand colors
        </div>
      </div>
      {/* </motion.div> */}
      {/* </div> */}
    </motion.div>
  );
};

export default TeampageCompOne;
