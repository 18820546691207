import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

// import deetsPhoneSolo from "../../assets/images/deetsPhoneSolo.svg";
import landingImagePhone from "../../assets/images/landingImagePhone.png";
import backgroundHome from "../../assets/images/backgroundHome.png";
import "./homePage.css";
import HomepageCompOne from "../../components/homepageCompOne/HomepageCompOne";
import HomepageCompTwo from "../../components/homepageCompTwo/HomepageCompTwo";
import HomepageCompThree from "../../components/homepageCompThree/HomepageCompThree";
import HomePageCompFour from "../../components/homepageCompFour/HomePageCompFour";
import HomepageCompFive from "../../components/homepageCompFive/HomepageCompFive";
import HomepageCompSix from "../../components/homepageCompSix/HomepageCompSix";
import GenFaqPage from "../../components/genFaqPage/GenFaqPage";

import { useLocation } from "react-router-dom";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariants = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: -1, scale: 0 },
};
const squareVariantsTw = {
  visible: { opacity: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0 },
};

const squareVariantsOn = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, x: -100 },
};

const squareVariantsOnComUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const HomePage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="bodyPos">
      <Navbar />
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
      >
        <div className="hhhhhhh">
          <div
            className="homePage-contFam"
            style={{
              backgroundImage: `url(${backgroundHome})`,
            }}
          >
            <div className="homePage-contFamLeft">
              <div className="homePage-contFamLeftF">
                Effective and frictionless <br />
                contact sharing
              </div>
              <div className="homePage-contFamLeftS">
                Deets digital card provides you with ease and freedom to share
                your contact and business information with as many persons as
                you want to network with.
              </div>{" "}
              <motion.div
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={squareVariants}
                className="square homePage-contFamLeftTBtn"
              >
                Get your card
              </motion.div>
            </div>

            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={squareVariantsOn}
              className="homePage-contFamRight"
            >
              <img src={landingImagePhone} />
            </motion.div>
          </div>
        </div>
        <div className="homePage-cont-marginFill">
          {/* <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariantsOnComUp}
        > */}
          <HomepageCompTwo />
          {/* </motion.div> */}

          <HomepageCompOne />

          <HomePageCompFour />
          <HomepageCompThree />

          <HomepageCompFive />
          <HomepageCompSix />
          <GenFaqPage />
        </div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default HomePage;
