import React, { useState, useEffect } from "react";
import "./teamPageCompTwo.css";
import teamComThree from "../../assets/images/teamComThree.svg";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const squareVariantsOn = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  hidden: { opacity: -1, x: 100 },
};

const squareVariantsOnUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const TeampageCompTwo = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsOnUp}
      className="teamCompthree-famGen"
    >
      {/* <div className="teamCompthree-famGen"> */}
      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariants}
        className="teamCompthree-flexleft"
      > */}
      <div className="teamCompthree-flexleft">
        <img src={teamComThree} />
        {/* </motion.div> */}
      </div>
      <div className="teamCompthree-flexright">
        {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOn}
        className="teamCompthree-flexright"
      > */}
        <div className="teamCompthree-flexRight1">
          Create{" "}
          <span style={{ color: "#36A6A4" }}>individual team cards </span>
          for all team members
        </div>
        <div className="teamCompthree-flexRight2">
          Individual team cards for team members can make editing team member
          information easily accessible and editable{" "}
        </div>
        {/* </motion.div> */}
      </div>
      {/* </div> */}
    </motion.div>
  );
};

export default TeampageCompTwo;
