import React, { useEffect } from "react";
import { useAuthorizer } from "@authorizerdev/authorizer-react";
import { showLoader, hideLoader } from "./loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AnimatePresence } from "framer-motion";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
  useNavigate,
  useLocation,
} from "react-router-dom";

import {
  ContactPage,
  FaqPage,
  WebHomePage,
  PricingPage,
  TeamsPage,
  SignUpCardPage,
} from "./pages";

const RoutesE = () => {
  const { user, loading, setUser, setToken, authorizerRef } = useAuthorizer();

  let navigate = useNavigate();

  useEffect(() => {
    if (user) {
      return navigate("/pricing_page"), notify();
    }
  }, [user]);

  const notify = () => {
    toast(
      `Welcome ${user?.family_name === null ? user?.email : user?.family_name}`,
      {
        toastId: "success1",
      }
    );
  };

  let routes = useRoutes([
    { path: "/contact_page", element: <ContactPage /> },
    { path: "/faq_page", element: <FaqPage /> },
    { path: "/", element: <WebHomePage /> },
    { path: "/pricing_page", element: <PricingPage /> },
    { path: "/teams_page", element: <TeamsPage /> },
    { path: "/signup", element: <SignUpCardPage /> },
  ]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          minHeight: "100vh",
        }}
      >
        {/* <showLoader /> */}
        Please wait...
      </div>
    );
  }
  return routes;
};

const AnimatedRoutes = () => {
  let location = useLocation();
  return (
    <AnimatePresence>
      <div location={location} key={location.pathname}>
        <RoutesE />
      </div>
    </AnimatePresence>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AnimatedRoutes />
    </Router>
  );
};

export default AppWrapper;
