import React, { useEffect } from "react";

import "./App.css";
import Root from "./Root";
// import Routes from "./routes";

function App() {
  return (
    <React.Fragment>
      {/* <Routes /> */}
      <Root />
    </React.Fragment>
  );
}

export default App;
