import React, { useState, useEffect } from "react";
import "./teamCompThree.css";
import teamComFour from "../../assets/images/teamComFour.svg";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const squareVariantsOn = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  hidden: { opacity: -1, x: -100 },
};
const squareVariantsOnUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};

const TeamCompThree = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsOnUp}
      className="teamComp-ThreeGenFam"
    >
      {/* <div className="teamComp-ThreeGenFam"> */}
      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOn}
        className="teamComp-ThreeFlex1"
      > */}

      <div className="teamComp-ThreeFlex1">
        <div className="teamComp-ThreeFlex1t">
          Create a <span style={{ color: "#36A6A4" }}>professional </span> look
          for all your team members
        </div>
        <div className="teamComp-ThreeFlex1tt">
          Issue specially designed cards for all your team members and view all
          the connections they make
        </div>
        {/* </motion.div> */}
      </div>

      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariants}
        className="teamComp-ThreeFlex2"
      > */}

      <div className="teamComp-ThreeFlex2">
        <img src={teamComFour} />
      </div>
      {/* </motion.div> */}
      {/* </div> */}
    </motion.div>
  );
};

export default TeamCompThree;
