import React, { useState, useEffect } from "react";
import "./homepageCompFive.css";
import pixelComFive from "../../assets/images/pixelComFive.jpg";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariantsOnUpO = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 100 },
};
const squareVariantsOn = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};
const squareVariantsBtn = {
  visible: { opacity: 1, scale: 1, transition: { duration: 2 } },
  hidden: { opacity: -2, scale: -2 },
};
const HomepageCompFive = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsOn}
      className="homepageCompFive-famG"
    >
      <div>
        <div className="homepageCompFive-famGImg">
          <img src={pixelComFive} />
        </div>

        {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOnUpO}
        className="homepageCompFive-famContt"
      > */}
        <div className="homepageCompFive-famContt">
          <div className="homepageCompFive-famConttext">Deets for Teams</div>
          <div className="homepageCompFive-famConttextTwo">
            Control, manage and enhance team member and client connection with a
            single tap.
          </div>

          {/* <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariantsBtn}
          className="homepageCompFive-famConttextBtn"
        > */}

          <div className="homepageCompFive-famConttextBtn">
            Coming Soon
            {/* </motion.div>
             */}
          </div>
          {/* </motion.div> */}
        </div>
      </div>
    </motion.div>
  );
};

export default HomepageCompFive;
