import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./pricingPage.css";
import pricingImg from "../../assets/images/pricingImg.svg";
import PricingCompTwo from "../../components/pricingCompTwo/PricingCompTwo";
import PricingCompThree from "../../components/pricingCompThree/PricingCompThree";
import GenFaqPage from "../../components/genFaqPage/GenFaqPage";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import PricingCompTwoC from "../../components/pricingCompTwoC/PricingCompTwoC";
import PricingCompTwoD from "../../components/pricingCompTwoD/PricingCompTwoD";
import PricingCompWhats from "../../components/pricingCompWhats/PricingCompWhats";

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const squareVariantsTw = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const squareVariantsOn = {
  visible: { opacity: 1, x: 0, transition: { duration: 3 } },
  hidden: { opacity: -1, x: -100 },
};

const squareVariantsOnUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 4 } },
  hidden: { opacity: -2, y: 100 },
};

const squareVariantsOnUpF = {
  visible: { opacity: 1, x: 0, transition: { duration: 5 } },
  hidden: { opacity: -3, x: 100 },
};

const squareVariantsBtn = {
  visible: { opacity: 1, scale: 1, transition: { duration: 7 } },
  hidden: { opacity: -7, scale: -7 },
};

const PricingPage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="bodyPos">
      <Navbar />
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
      >
        <div className="pricingPage-top">
          <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariants}
            className="pricingPage-topSelFam"
          >
            <div className="pricingPage-topSelImg">
              <img src={pricingImg} />
            </div>
            <div className="pricingPage-topt">Select Upgrade Plans</div>
            <div className="pricingPage-toptt">
              Select personal upgrade plans for yourself or your team
            </div>
          </motion.div>
          <div className="pricingComp-famFlexx">
            {/* <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariantsOn}
            className="homeCompFour-flexLeft"
          > */}
            <PricingCompTwo />
            {/* </motion.div> */}
            {/* <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariantsOnUp}
            className="homeCompFour-flexLeft"
          > */}
            {/* <PricingCompTwo /> */}
            <PricingCompTwoC />
            {/* </motion.div> */}
            {/* <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariantsOnUpF}
            className="homeCompFour-flexLeft"
          > */}
            {/* <PricingCompTwo /> */}
            <PricingCompTwoD />
            {/* </motion.div> */}
          </div>

          {/* <PricingCompThree /> */}
          <PricingCompWhats />
          <GenFaqPage />
        </div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default PricingPage;
