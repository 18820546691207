import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import teamSpiralBack from "../../assets/images/teamSpiralBack.svg";
import teamLandPhone from "../../assets/images/teamLandPhone.png";

import teamLandd from "../../assets/images/teamLandd.png";
import teamLandLine from "../../assets/images/teamLandLine.svg";
import teamLandPhoneSub from "../../assets/images/teamLandPhoneSub.svg";
import comingSoons from "../../assets/images/comingSoons.svg";

import comingSoonMob from "../../assets/images/comingSoonMob.svg";

import "./teamsPage.css";
import HomepageCompSix from "../../components/homepageCompSix/HomepageCompSix";
import TeampageCompOne from "../../components/teampageCompOne/TeampageCompOne";
import TeampageCompTwo from "../../components/teampageCompTwo/TeampageCompTwo";
import TeamCompThree from "../../components/teamCompThree/TeamCompThree";
import TeamCompFour from "../../components/teamCompFour/TeamCompFour";
import GenFaqPage from "../../components/genFaqPage/GenFaqPage";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";

const squareVariants = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 0 },
};

const squareVariantsO = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
  hidden: { opacity: -1, scale: -1 },
};

const squareVariantsT = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1.5 } },
  hidden: { opacity: -2, scale: -2 },
};

const squareVariantsTh = {
  visible: { opacity: 1, scale: 1, transition: { duration: 2 } },
  hidden: { opacity: -3, scale: -3 },
};

const squareVariantsF = {
  visible: { opacity: 1, scale: 1, transition: { duration: 2.5 } },
  hidden: { opacity: -4, scale: -4 },
};

const squareVariantsSi = {
  visible: { opacity: 1, scale: 1, transition: { duration: 3.5 } },
  hidden: { opacity: -4, scale: -4 },
};

const TeamsPage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const location = useLocation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="bodyPos">
      <Navbar />
      <motion.div
        className="bodyPos"
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
      >
        <div
          style={{
            backgroundImage: `url(${teamSpiralBack})`,
            backgroundColor: "#fefefe",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div className="teamsPageGenFam-fam">
            <div className="teamsPageGenFam-famtexttd">
              <motion.span
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={squareVariants}
              >
                {" "}
                Create,
              </motion.span>{" "}
              <motion.span
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={squareVariantsO}
              >
                {" "}
                Share,
              </motion.span>
              <motion.span
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={squareVariantsT}
                className="teamsPageGenFam-famgtgt"
              >
                Connect
              </motion.span>{" "}
              <motion.span
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={squareVariantsTh}
              >
                & control your{" "}
              </motion.span>
              <motion.span
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={squareVariantsF}
                className="teamsPageGenFam-famtgspa"
              >
                teams <img src={teamLandLine} />
              </motion.span>
            </div>
            {screenWidth > 992 ? (
              <div className="teamsPageGenFam-secondimg">
                <div className="teamsPageGenFam-secondrel">
                  <img src={teamLandPhone} />
                </div>

                <div className="teamsPageGenFam-secondabs">
                  <img src={teamLandPhoneSub} />
                </div>
                <div className="teamsPageComingS">
                  {" "}
                  <img src={comingSoons} />{" "}
                </div>
              </div>
            ) : (
              <div>
                <div className="teamsPageGenFam-secondrel">
                  <img src={teamLandd} />
                </div>
                <div className="comingSoonMobile">
                  <img src={comingSoonMob} />
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          // style={{ background: "#ffffff" }}
          className="teamsPageGenFam-famlineMar"
        >
          <TeampageCompOne />

          <TeampageCompTwo />
          <TeamCompThree />
          {/* <TeampageCompTwo /> */}
          <TeamCompFour />
          <GenFaqPage />
        </div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default TeamsPage;
