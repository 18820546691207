import React from "react";
import deetsLogoBlack from "../../assets/icons/deetsLogoBlack.svg";
import { Link } from "react-router-dom";
import "./authorizerNav.css";

const AuthorizerNav = () => {
  return (
    <div className="authorizerNavGenFams">
      <Link to="/" textDecoration="none" className="authnavbar-headingLogo">
        <img src={deetsLogoBlack} />
      </Link>
    </div>
  );
};

export default AuthorizerNav;
