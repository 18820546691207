import React from "react";
import deetsLogoFooterW from "../../assets/icons/deetsLogoFooterW.svg";

import "./footer.css";

const Footer = () => {
  return (
    <div className="webfooterPos">
      <div className="web-footerFama">
        <div className="web-footerMain">
          <div className="web-footerFamLogo">
            <img src={deetsLogoFooterW} />
          </div>
          <div className="web-footerflexOne">
            <div className="web-footerGet">Get in</div>
            <div className="web-footerGetLog">Login</div>
            <div className="web-footerGetCard">Get a card</div>
          </div>

          <div className="web-footerflexTwo">
            <div className="web-footerDeets">Deets Card</div>
            <div className="web-footerDeetsIndi">Individual</div>
            <div className="web-footerDeetsTeam">Teams</div>
          </div>

          <div className="web-footerflexThree">
            <div className="web-footerflexHelp">Help and Support</div>
            <div className="web-footerSubflexThree">
              <div className="web-footerSubflexThreess">
                <div className="web-footerSubflexThreessFaq">FAQ</div>
                <div className="web-footerSubflexThreessCont"> Contact Us</div>
              </div>
              <div className="web-footerSubflexThreebbb">
                <div className="web-footerSubflexThreebbbcond">
                  Terms & Condition
                </div>
                <div className="web-footerSubflexThreebbbpriva">Privacy</div>
              </div>
            </div>
          </div>
        </div>

        <div className="web-footerSubflexCopy">&copy;2022 - DEETS</div>
      </div>
    </div>
  );
};

export default Footer;
