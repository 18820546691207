import React from "react";
import { ProtectedLayout } from "./protected";

import ContactPageRoute from "./screens/contactPage/ContactPage";
import FaqPageRoute from "./screens/faqPage/FaqPage";
import WebHomePageRoute from "./screens/homePage/HomePage";
import PricingPageRoute from "./screens/pricingPage/PricingPage";
import SignUpCard from "./screens/signUpCard/SignUpCard";
import TeamsPageRoute from "./screens/teamsPage/TeamsPage";

export const ContactPage = () => {
  return <ContactPageRoute />;
};

export const FaqPage = () => {
  return <FaqPageRoute />;
};

export const WebHomePage = () => {
  return <WebHomePageRoute />;
};

export const PricingPage = () => {
  return <PricingPageRoute />;
};

export const TeamsPage = () => {
  return <TeamsPageRoute />;
};

export const SignUpCardPage = () => {
  return <SignUpCard />;
};
