import React, { useState, useEffect } from "react";
import "./genFaqPage.css";
import Collapsible from "react-collapsible";
import downArrow from "../../assets/icons/downArrow.svg";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariants = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  hidden: { opacity: -1, x: -100 },
};

const squareVariantsOn = {
  visible: { opacity: 1, y: 0, transition: { duration: 1.5 } },
  hidden: { opacity: -1, y: 50 },
};

const GenFaqPage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }

    setSelected(i);
  };
  const data = [
    {
      question: "How is the sharing done?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus ab recusandae explicabo placeat expedita, aspernatur laboriosam quisquam laudantium corrupti, nisi, ipsum amet adipisci? Ad, quaerat. Voluptatibus, voluptas aliquam qui reprehenderit labore corrupti voluptates magnam neque distinctio ducimus sunt, reiciendis officia nesciunt nostrum ea quae incidunt debitis quisquam commodi ab laboriosam! Illo ullam cumque, ad unde assumenda quas quaerat exercitationem ipsa quibusdam? Minima molestiae illum quae voluptatum architecto aperiam nisi modi nostrum quos, quis neque error adipisci enim eum, delectus obcaecati praesentium. Perspiciatis laborum, laboriosam, quis minima, rerum neque in quod libero nisi doloremque explicabo consectetur sit dolorem odio quia voluptatibus?",
    },
    {
      question: "How do set up for teams?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus ab recusandae explicabo placeat expedita, aspernatur laboriosam quisquam laudantium corrupti, nisi, ipsum amet adipisci? Ad, quaerat. Voluptatibus, voluptas aliquam qui reprehenderit labore corrupti voluptates magnam neque distinctio ducimus sunt, reiciendis officia nesciunt nostrum ea quae incidunt debitis quisquam commodi ab laboriosam! Illo ullam cumque, ad unde assumenda quas quaerat exercitationem ipsa quibusdam? Minima molestiae illum quae voluptatum architecto aperiam nisi modi nostrum quos, quis neque error adipisci enim eum, delectus obcaecati praesentium. Perspiciatis laborum, laboriosam, quis minima, rerum neque in quod libero nisi doloremque explicabo consectetur sit dolorem odio quia voluptatibus?",
    },
    {
      question: "How do I protect my information?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus ab recusandae explicabo placeat expedita, aspernatur laboriosam quisquam laudantium corrupti, nisi, ipsum amet adipisci? Ad, quaerat. Voluptatibus, voluptas aliquam qui reprehenderit labore corrupti voluptates magnam neque distinctio ducimus sunt, reiciendis officia nesciunt nostrum ea quae incidunt debitis quisquam commodi ab laboriosam! Illo ullam cumque, ad unde assumenda quas quaerat exercitationem ipsa quibusdam? Minima molestiae illum quae voluptatum architecto aperiam nisi modi nostrum quos, quis neque error adipisci enim eum, delectus obcaecati praesentium. Perspiciatis laborum, laboriosam, quis minima, rerum neque in quod libero nisi doloremque explicabo consectetur sit dolorem odio quia voluptatibus?",
    },
    {
      question:
        "What is the difference between a digital card and a virtual business card?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus ab recusandae explicabo placeat expedita, aspernatur laboriosam quisquam laudantium corrupti, nisi, ipsum amet adipisci? Ad, quaerat. Voluptatibus, voluptas aliquam qui reprehenderit labore corrupti voluptates magnam neque distinctio ducimus sunt, reiciendis officia nesciunt nostrum ea quae incidunt debitis quisquam commodi ab laboriosam! Illo ullam cumque, ad unde assumenda quas quaerat exercitationem ipsa quibusdam? Minima molestiae illum quae voluptatum architecto aperiam nisi modi nostrum quos, quis neque error adipisci enim eum, delectus obcaecati praesentium. Perspiciatis laborum, laboriosam, quis minima, rerum neque in quod libero nisi doloremque explicabo consectetur sit dolorem odio quia voluptatibus?",
    },
    {
      question: "How do I share my contact using the DEETS card?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus ab recusandae explicabo placeat expedita, aspernatur laboriosam quisquam laudantium corrupti, nisi, ipsum amet adipisci? Ad, quaerat. Voluptatibus, voluptas aliquam qui reprehenderit labore corrupti voluptates magnam neque distinctio ducimus sunt, reiciendis officia nesciunt nostrum ea quae incidunt debitis quisquam commodi ab laboriosam! Illo ullam cumque, ad unde assumenda quas quaerat exercitationem ipsa quibusdam? Minima molestiae illum quae voluptatum architecto aperiam nisi modi nostrum quos, quis neque error adipisci enim eum, delectus obcaecati praesentium. Perspiciatis laborum, laboriosam, quis minima, rerum neque in quod libero nisi doloremque explicabo consectetur sit dolorem odio quia voluptatibus?",
    },
    {
      question: "How will it benefit me to use DEETS digital card?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus ab recusandae explicabo placeat expedita, aspernatur laboriosam quisquam laudantium corrupti, nisi, ipsum amet adipisci? Ad, quaerat. Voluptatibus, voluptas aliquam qui reprehenderit labore corrupti voluptates magnam neque distinctio ducimus sunt, reiciendis officia nesciunt nostrum ea quae incidunt debitis quisquam commodi ab laboriosam! Illo ullam cumque, ad unde assumenda quas quaerat exercitationem ipsa quibusdam? Minima molestiae illum quae voluptatum architecto aperiam nisi modi nostrum quos, quis neque error adipisci enim eum, delectus obcaecati praesentium. Perspiciatis laborum, laboriosam, quis minima, rerum neque in quod libero nisi doloremque explicabo consectetur sit dolorem odio quia voluptatibus?",
    },
    {
      question: "What phones can work with the DEETS card?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus ab recusandae explicabo placeat expedita, aspernatur laboriosam quisquam laudantium corrupti, nisi, ipsum amet adipisci? Ad, quaerat. Voluptatibus, voluptas aliquam qui reprehenderit labore corrupti voluptates magnam neque distinctio ducimus sunt, reiciendis officia nesciunt nostrum ea quae incidunt debitis quisquam commodi ab laboriosam! Illo ullam cumque, ad unde assumenda quas quaerat exercitationem ipsa quibusdam? Minima molestiae illum quae voluptatum architecto aperiam nisi modi nostrum quos, quis neque error adipisci enim eum, delectus obcaecati praesentium. Perspiciatis laborum, laboriosam, quis minima, rerum neque in quod libero nisi doloremque explicabo consectetur sit dolorem odio quia voluptatibus?",
    },
  ];

  return (
    <div className="Faq-genFam">
      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariants}
        className="Faq-accordion-heading"
      > */}
      <div className="Faq-accordion-heading">
        Frequently Asked Questions
        {/* </motion.div> */}
      </div>
      <div className="Faq-accordion">
        {data.map((item, i) => (
          // <motion.div
          //   ref={ref}
          //   animate={controls}
          //   initial="hidden"
          //   variants={squareVariantsOn}
          //   className="Faq-item"
          // >
          <div className="Faq-item">
            <div
              className="Faq-headingFlex"
              onClick={() => {
                toggle(i);
              }}
            >
              {" "}
              <div
                className={
                  selected === i ? "Faq-titleArrowRota" : "Faq-titleArrow"
                }
              >
                <img src={downArrow} />
              </div>
              <div className="Faq-title">{item.question}</div>
            </div>
            <div
              className={selected === i ? "Faq-content show" : "Faq-content"}
            >
              {item.answer}
            </div>
            {/* </motion.div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GenFaqPage;
