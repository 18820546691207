import React, { useState, useEffect } from "react";
import "./teamCompFour.css";
import teamComFive from "../../assets/images/teamComFive.svg";
import teamCompFiveUP from "../../assets/images/teamCompFiveUP.png";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const squareVariantsOn = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  hidden: { opacity: -1, x: 100 },
};

const squareVariantsOnUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};
const TeamCompFour = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsOnUp}
      className="teamCompFour-famGenhh"
    >
      {/* <div className="teamCompFour-famGenhh"> */}
      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariants}
        className="teamCompFour-flexleftFour"
      > */}

      <div className="teamCompFiveUpImg">
        <img src={teamCompFiveUP} />
      </div>
      {/* </motion.div> */}
      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOn}
        className="teamCompFour-flexright"
      > */}

      <div>
        <div className="teamCompFour-flexRight1">
          Match your
          <span style={{ color: "#36A6A4", marginLeft: "10px" }}>
            brand theme{" "}
          </span>
          with your shared profile
        </div>
        <div className="teamCompFour-flexRight2">
          You can share your contact with anyone and they get to see your
          business logo and theme, customised by you.
        </div>
        {/* </motion.div> */}
      </div>
      {/* </div> */}
    </motion.div>
  );
};

export default TeamCompFour;
