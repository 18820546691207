import React, { useEffect } from "react";
import "./signUpCard.css";
import { Authorizer } from "@authorizerdev/authorizer-react";
import Navbar from "../../components/navbar/Navbar";
import AuthorizerNav from "../../components/authorizerNav/AuthorizerNav";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const SignUpCard = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {/* <Navbar /> */}
      <AuthorizerNav />
      <div className="authorizer-Gen">
        <Authorizer />
      </div>
    </>
  );
};

export default SignUpCard;
