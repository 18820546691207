import React, { useState } from "react";
import { useAuthorizer } from "@authorizerdev/authorizer-react";

import "./leftNav.css";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";

const LeftNav = ({
  open,
  openLogoutBtn,
  closeLogoutBtn,
  openLogoutPage,
  closeLogoutPage,
  closeLogoutPageTes,
}) => {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const { user, loading, setUser, token, setToken, authorizerRef } =
    useAuthorizer();

  let navigate = useNavigate();

  const onLogout = async () => {
    await authorizerRef.logout();
    setToken(null);
    setUser(null);
    navigate("/");
  };

  return (
    <>
      <div
        open={open}
        style={{
          transform: `${open ? "translateX(0)" : "translateX(150%)"}`,
          transition: "transform 0.3s ease-in-out",
        }}
        className="sidemenu-family-cont"
        onClick={() => {
          closeLogoutPage();
        }}
      >
        <div className="dividedClass">
          <div className="top-navbar-rel">
            <div className="cont-main">
              {/* <a
              href="https://deets-admin-app.vercel.app/login"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <div className="leftNavbar-opt">Login</div>
            </a> */}

              {/* {!user ? ( */}

              {/* ) : (
              <></>
            )} */}

              {/* {user ? (
              <div className="welcome-userNameMob">
                {" "}
                Welcome 
                {user?.family_name === null ? user?.email : user?.family_name}
              </div>
            ) : (
              <></>
            )} */}

              {/* {user ? (
              <div className="leftNavbar-opt" onClick={onLogout}>
                Logout
              </div>
            ) : ( */}

              {/* )} */}

              {/* <Link
              to="/"
              className="leftNavbar-opt"
              style={{ textDecoration: "none" }}
            >
              Get your Card
            </Link> */}

              <Link
                to="/"
                className={
                  splitLocation[1] === ""
                    ? "leftNavbar-opt-active"
                    : "leftNavbar-opt"
                }
                style={{ textDecoration: "none" }}
              >
                Home{" "}
              </Link>

              <Link
                to="/pricing_page"
                className={
                  splitLocation[1] === "pricing_page"
                    ? "leftNavbar-opt-active"
                    : "leftNavbar-opt"
                }
                style={{ textDecoration: "none" }}
              >
                Pricing
              </Link>
              <Link
                to="/teams_page"
                className={
                  splitLocation[1] === "teams_page"
                    ? "leftNavbar-opt-active"
                    : "leftNavbar-opt"
                }
                style={{ textDecoration: "none" }}
              >
                Teams
              </Link>
              <Link
                to="/faq_page"
                className={
                  splitLocation[1] === "faq_page"
                    ? "leftNavbar-opt-active"
                    : "leftNavbar-opt"
                }
                style={{ textDecoration: "none" }}
              >
                FAQ
              </Link>
              <Link
                to="/contact_page"
                className={
                  splitLocation[1] === "contact_page"
                    ? "leftNavbar-opt-active"
                    : "leftNavbar-opt"
                }
                style={{ textDecoration: "none" }}
              >
                Contact Us
              </Link>
              <Link
                to="/"
                className="leftNavbar-opt"
                style={{ textDecoration: "none" }}
              >
                Terms and conditions
              </Link>
              <Link
                to="/"
                className="leftNavbar-opt"
                style={{ textDecoration: "none" }}
              >
                Privacy policy
              </Link>

              <Link
                to="/signup"
                style={{ textDecoration: "none", marginTop: "70px" }}
                className="leftNavbar-opt"
              >
                Login
              </Link>
              <Link
                to="/signup"
                style={{ textDecoration: "none" }}
                className="leftNavbar-opt"
              >
                Get your Card
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftNav;
