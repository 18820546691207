import React, { useState, useEffect } from "react";
import "./navbar.css";
import deetsLogoBlack from "../../assets/icons/deetsLogoBlack.svg";
import openHamburgerIcon from "../../assets/icons/openHamburgerIcon.svg";
import { useAuthorizer } from "@authorizerdev/authorizer-react";

import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import Burger from "../mobileNav/Burger";

const Navbar = () => {
  const location = useLocation();
  const { user, loading, setUser, token, setToken, authorizerRef } =
    useAuthorizer();
  let navigate = useNavigate();

  const onLogout = async () => {
    await authorizerRef.logout();
    setToken(null);
    setUser(null);
    navigate("/");
  };
  const { pathname } = location;
  console.log(user);
  const splitLocation = pathname.split("/");
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [switchSmallNav, setSwitchSmallNav] = useState(false);

  const switchHandler = () => {
    setSwitchSmallNav(true);
  };

  return (
    <div className="fiiii">
      <div className="navbar-headingMain">
        <Link to="/" textDecoration="none" className="navbar-headingLogo">
          <img src={deetsLogoBlack} />
        </Link>
        {screenWidth < 992 && (
          <div>
            <Burger />
          </div>
        )}
        {screenWidth > 992 && (
          <div className="navbar-headingTitle ">
            <Link
              to="/"
              className={
                splitLocation[1] === "" ? "activeHeader" : "navbar-headTitleOne"
              }
            >
              Home
            </Link>
            <Link
              to="/teams_page"
              className={
                splitLocation[1] === "teams_page"
                  ? "activeHeader"
                  : "navbar-headTitleOne"
              }
            >
              Teams
            </Link>
            <Link
              to="/pricing_page"
              className={
                splitLocation[1] === "pricing_page"
                  ? "activeHeader"
                  : "navbar-headTitleOne"
              }
            >
              Pricing
            </Link>
            <Link
              to="/faq_page"
              className={
                splitLocation[1] === "faq_page"
                  ? "activeHeader"
                  : "navbar-headTitleOne"
              }
            >
              FAQ
            </Link>
            <Link
              to="/contact_page"
              className={
                splitLocation[1] === "contact_page"
                  ? "activeHeader"
                  : "navbar-headTitleOne"
              }
            >
              Contact Us
            </Link>

            {/* {!user ? ( */}
            <Link
              to="/signup"
              style={{ textDecoration: "none" }}
              className="navbar-headTitleLogin"
            >
              Login
            </Link>
            {/* ) : (
              <></>
            )} */}
            {/* {user ? (
              <button className="navbar-headTitleOrder" onClick={onLogout}>
                Logout
              </button>
            ) : ( */}
            <Link
              to="/signup"
              style={{ textDecoration: "none" }}
              className="navbar-headTitleOrder"
            >
              Get your Card
            </Link>
            {/* )} */}

            {/* {user ? (
              <div className="welcome-userName">
                {" "}
                Welcome{" "}
                <div className="welcome-userNameText">
                  {user?.family_name === null ? user?.email : user?.family_name}
                </div>
              </div>
            ) : (
              <></>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
