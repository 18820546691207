import React, { useState, useEffect } from "react";
import "./homepageCompFour.css";
import tickSquare from "../../assets/images/tickSquare.svg";
import pixelComFour from "../../assets/images/pixelComFour.png";
import pixelComFourM from "../../assets/images/pixelComFourM.png";

import slideReMob from "../../assets/images/slideReMob.png";
import slideReDesk from "../../assets/images/slideReDesk.png";
import threeDots from "../../assets/images/threeDots.svg";
import Carousel from "nuka-carousel";
import homeSlide1 from "../../assets/images/homeSlide1.png";

import homeSlide2 from "../../assets/images/homeSlide2.png";

import homeSlide3 from "../../assets/images/homeSlide3.png";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const squareVariantsTw = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const squareVariantsOn = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  hidden: { opacity: -1, x: -100 },
};

const squareVariantsOnUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: -1, y: 100 },
};
const squareVariantsOnUpO = {
  visible: { opacity: 1, y: 0, transition: { duration: 3 } },
  hidden: { opacity: -3, y: 100 },
};
const squareVariantsOnUpT = {
  visible: { opacity: 1, y: 0, transition: { duration: 4 } },
  hidden: { opacity: -4, y: 100 },
};

const squareVariantsOnUpTh = {
  visible: { opacity: 1, y: 0, transition: { duration: 5 } },
  hidden: { opacity: -5, y: 100 },
};

const squareVariantsOnUpThOn = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};
const squareVariantsOnUpF = {
  visible: { opacity: 1, y: 0, transition: { duration: 6 } },
  hidden: { opacity: -6, y: 100 },
};

const squareVariantsBtn = {
  visible: { opacity: 1, scale: 1, transition: { duration: 7 } },
  hidden: { opacity: -7, scale: -7 },
};
const HomePageCompFour = () => {
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsOnUpThOn}
      className="homeCompFour-flexMain"
    >
      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOn}
        className="homeCompFour-flexLeft"
      > */}

      <div className="homeCompFour-flexLeft">
        <div className="homeCompFour-flexLeftText">How to get yours</div>
        <div className="homeCompFour-flexLeftTickFam">
          {/* <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={screenWidth > 992 ? squareVariantsOnUp : null}
            className="homeCompFour-flexLeftTickOne"
          > */}
          <div className="homeCompFour-flexLeftTickOne">
            <div className="homeCompFour-flexLeftTickOneImg">
              <img src={tickSquare} />
            </div>{" "}
            <div className="homeCompFour-flexLeftTickOneText">
              Place order for yourself/team
            </div>
            {/* </motion.div> */}
          </div>

          {/* <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariantsOnUpO}
            className="homeCompFour-flexLeftTickOne"
          > */}
          <div className="homeCompFour-flexLeftTickOne">
            <div className="homeCompFour-flexLeftTickOneImg">
              <img src={tickSquare} />
            </div>{" "}
            <div className="homeCompFour-flexLeftTickOneText">Make payment</div>
            {/* </motion.div> */}
          </div>
          {/* <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariantsOnUpT}
            className="homeCompFour-flexLeftTickTwo"
          > */}
          <div className="homeCompFour-flexLeftTickTwo">
            <div className="homeCompFour-flexLeftTickTwoImg">
              <img src={tickSquare} />
            </div>{" "}
            <div className="homeCompFour-flexLeftTickTwoText">
              Get physical card (s)
            </div>
            {/* </motion.div> */}
          </div>

          {/* <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariantsOnUpTh}
            className="homeCompFour-flexLeftTickTwo"
          > */}
          <div className="homeCompFour-flexLeftTickTwo">
            <div className="homeCompFour-flexLeftTickTwoImg">
              <img src={tickSquare} />
            </div>{" "}
            <div className="homeCompFour-flexLeftTickTwoText">
              Login and customise profile (s)
            </div>
            {/* </motion.div> */}
          </div>
          {/* <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={squareVariantsOnUpF}
            className="homeCompFour-flexLeftTickTwo"
          > */}
          <div className="homeCompFour-flexLeftTickTwo">
            <div className="homeCompFour-flexLeftTickTwoImg">
              <img src={tickSquare} />
            </div>{" "}
            <div className="homeCompFour-flexLeftTickTwoText">
              Connect Connect !!
            </div>
            {/* </motion.div> */}
          </div>
        </div>

        {/* <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariantsBtn}
          className="homeCompFour-OrderCardBtn"
        > */}
        <div className="homeCompFour-OrderCardBtn">
          Get yours now
          {/* </motion.div> */}
        </div>
        {/* </motion.div> */}
      </div>

      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariants}
        className="homeCompFour-flexRightFam"
      > */}
      <div className="homeCompFour-flexRightFam">
        <div>
          {screenWidth < 992 ? (
            <div className="slideRemobImg">
              <img src={slideReMob} />
            </div>
          ) : (
            <div className="slideReDeskImg">
              <img src={slideReDesk} />
            </div>
          )}

          {/* <Carousel
            renderAnnounceSlideMessage={({ currentSlide, slideCount }) =>
              `Slide ${currentSlide + 1} of ${slideCount}`
            }
            autoplay={true}
            // autoplayInterval={0.1}
            // autoplayReverse={true}
            enableKeyboardControls={true}
            dragging={true}
            // speed={1}
            swiping={true}
            // transitionMode={["scroll3d"]}
            easing="easeInOutElastic"
            speed={500}
            // autoplayReverse={true}
            // infiniteScroll={true}
            autoplayInterval={4000}
            wrapAround={true}
            // renderCenterLeftControls={({ previousSlide }) => (
            //   <button onClick={previousSlide}>Previous</button>
            // )}
            // renderCenterRightControls={({ nextSlide }) => (
            //   <button onClick={nextSlide}>Next</button>
            // )}
          >
            <div className="homeCompFour-flexRightImgOne">
              <img src={homeSlide1} />
            </div>
            <div className="homeCompFour-flexRightImgOne">
              <img src={homeSlide2} />
            </div>
            <div className="homeCompFour-flexRightImgOne">
              <img src={homeSlide3} />
            </div>
          </Carousel> */}
        </div>
        {/* </motion.div> */}
      </div>
    </motion.div>
  );
};

export default HomePageCompFour;
