import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import GenFaqPage from "../../components/genFaqPage/GenFaqPage";
import "./faqPage.css";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const FaqPage = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="bodyPos">
      <Navbar />
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
      >
        <div className="faqPage-GenFam">
          <GenFaqPage />
        </div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default FaqPage;
