import React, { useState, useEffect } from "react";
import "./pricingCompWhats.css";
import pricingRectangle from "../../assets/images/pricingRectangle.jpg";
import pricingRectangleTwo from "../../assets/images/pricingRectangleTwo.jpg";
import pricingWhatsApp from "../../assets/images/pricingWhatsApp.svg";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const PricingCompWhats = () => {
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div
      style={{
        backgroundImage:
          screenWidth < 992
            ? `url(${pricingRectangleTwo})`
            : `url(${pricingRectangle})`,
        backgroundColor: "#fefefe",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      }}
      className="pricingCompWhatsBack"
    >
      <div className="pricingWhatsGenn">
        <div className=" pricingWhatsGenn-Texttx">
          Want to pay with bank transfer or have questions?
        </div>
        <div className="pricingWhatsGenn-section-V">
          <div className="pricingWhatsGenn-IMG">
            <img src={pricingWhatsApp} />
          </div>
          <div className="pricingWhatsGenn-WhatsMessa">
            message us on Whatsapp
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingCompWhats;
