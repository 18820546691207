import React, { useState, useEffect } from "react";
import "./homepageCompOne.css";
import pixelComOne from "../../assets/images/pixelComOne.png";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

// const squareVariants = {
//   visible: { x: -10, transition: { duration: 1 } },
//   hidden: { x: 10, scale: 0 },
// };

const squareVariants = {
  visible: { opacity: 1, transition: { duration: 2 } },
  hidden: { opacity: 0 },
};

const squareVariantsOn = {
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  hidden: { opacity: -1, x: 100 },
};

const squareVariantsOnUp = {
  visible: { opacity: 1, y: 0, transition: { duration: 2 } },
  hidden: { opacity: 0, y: 100 },
};
const HomepageCompOne = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariantsOnUp}
      // initial={false}
      // animate={{ x: 10 }}

      className="homepageComOne-firstFlex"
    >
      {/* <div> */}
      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariants}
        // initial={false}
        // animate={{ x: 10 }}

        className="homepageComOne-rightFlex"
      > */}
      <div className="homepageComOne-rightFlex">
        <div>
          <img src={pixelComOne} />
        </div>
        {/* </motion.div> */}
      </div>

      {/* <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={squareVariantsOn}
        // initial={false}
        // animate={{ x: 10 }}

        className="homepageComOne-leftFlex"
      > */}
      <div className="homepageComOne-leftFlex">
        <div className="homepageComOne-leftFlexOne">
          Make networking remarkable
        </div>

        <div className="homepageComOne-leftFlexTwo">
          You automatically stand out of the crowd when you use the deets
          digital card. You create a memory and stand out of the crowd while
          networking with others and sharing your contact.
        </div>
        {/* </motion.div> */}
      </div>
      {/* </div> */}
    </motion.div>
  );
};

export default HomepageCompOne;
